"use client";
import { withSentry } from "@sentry/remix";
import { ThemeProvider, useTheme, PreventFlashOnWrongTheme } from "remix-themes";
import posthog from "posthog-js";
import { json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  isRouteErrorResponse,
  useLocation,
  useNavigate,
  useFetcher,
} from "@remix-run/react";

import { getUser, themeSessionResolver } from "./session.server";
import { BlackButton } from "./components/Buttons";
import FormInput from "./components/FormInput";
import { useEffect, useState } from "react";
import { AppProvider } from "./context/app-provider";
import { LogError } from "./utils/log";
import { Alert, ArrowLeft } from "./components/Icons";
import { ReaderProvider } from "./context/reader-provider";
import mainStyles from "~/styles/main.css?url";

export const links = () => {
  return [
    { rel: "stylesheet", href: mainStyles },
    { rel: "stylesheet", href: "https://use.typekit.net/jzd8whz.css" },
    { rel: "manifest", href: "/manifest.json" },
  ];
};

export const meta = () => {
  return [
    { charset: "utf-8" },
    { title: "Mindsera" },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1.0",
    },
    {
      name: "theme-color",
      content: "#fff",
      media: "(prefers-color-scheme: light)",
    },
    {
      name: "theme-color",
      content: "#1f2937",
      media: "(prefers-color-scheme: dark)",
    },
    { name: "mobile-web-app-capable", content: "yes" },
    { name: "robots", content: "noindex" },
    // og: Open Graph
    { property: "og:title", content: "Login to Mindsera" },
    { property: "og:type", content: "website" },
    { property: "og:url", content: "https://beta.mindsera.com" },
    {
      property: "og:image",
      content: "https://beta.mindsera.com/images/mindsera-og.jpg",
    },
    { property: "og:description", content: "" },
    // twitter
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "@mindseraHQ" },
    { name: "twitter:creator", content: "@mindseraHQ" },
    { name: "twitter:title", content: "Login to Mindsera" },
    { name: "twitter:description", content: "" },
    {
      name: "twitter:image",
      content: "https://beta.mindsera.com/images/mindsera-og.jpg",
    },
  ];
};

export async function loader({ request }) {
  const { getTheme } = await themeSessionResolver(request);

  const user = await getUser(request);

  return json({
    user,
    userTheme: getTheme(),
  });
}

function App() {
  const location = useLocation();
  const { user, userTheme } = useLoaderData();
  const [theme] = useTheme();
  const fetcher = useFetcher();

  // Capturing pageviews manually due to how Remix works.
  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  // Identifying user for PostHog, once per session. Timeout needed because for some reason, it doesn't work without.
  useEffect(() => {
    let timeoutId;
    if (user) {
      // console.log("identifying user for PostHog", user);
      timeoutId = setTimeout(() => {
        posthog.identify(user.id, {
          email: user.email,
          name: user.name,
          v2: user.v2,
          betaTester: user.betaTester,
          createdAt: user.createdAt,
          channel: user.channel,
          isPremium: user.endDate ? new Date(user.endDate) > new Date() : false,
          subscriptionPlan: user.subscriptionPlan ? user.subscriptionPlan : false,
        });
        // get user timezone, update db if different from default.
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (timezone !== user.timezone && user.timezoneAuto) {
          fetcher.submit({ timezone }, { method: "POST", action: "/api/user/update" });
        } else if (user.theme === "dark" || user.theme === "system") {
          fetcher.submit({ theme: "light" }, { method: "POST", action: "/api/user/update" });
        }
      }, 100);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [user?.id]);

  return (
    <html
      lang="en"
      className={`h-full ${user?.v2 ? "bg-white" : "bg-gray-100"} dark:bg-gray-900`}
      data-theme={theme ?? ""}
    >
      <head>
        <Meta />
        <Links />
        <PreventFlashOnWrongTheme ssrTheme={userTheme} />
      </head>
      <body className={user?.v2 ? "relative h-full bg-white" : "relative h-full"}>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();
  const location = useLocation();
  const fetcher = useFetcher();

  const is404 = isRouteErrorResponse(error) && error.status === 404;

  const title = is404 ? "404 Page not found" : "Oops! An error has occurred.";
  const message = is404
    ? "This page must be hiding between the lines. Let’s get you back to where you belong—try going back or return to the dashboard."
    : "Please describe what happened so we can detect and fix the issue faster. Thank you for helping out.";

  const [userInput, setUserInput] = useState("");
  const [hasSent, setHasSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (!is404) {
      LogError(error);
      posthog.capture("Error Page", { error: error });
    } else {
      posthog.capture("404 Error");
    }
  }, []);

  const sendErrorReport = () => {
    fetcher.submit({ userInput }, { method: "post", action: "/api/report" });
    posthog.capture("Error report sent", { userInput });
    setIsSending(true);
  };

  useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data) {
      if (fetcher.data.success) {
        setHasSent(true);
      } else {
        LogError("Error sending error report: ", fetcher.data);
      }
      setIsSending(false);
    }
  }, [fetcher.state]);

  const handleReturn = () => {
    // If we have history and referrer is from same origin, go back
    // Otherwise go to homepage
    try {
      const referrer = document.referrer;
      const isSameOrigin = referrer && new URL(referrer).origin === window.location.origin;

      navigate(window.history.length > 1 && isSameOrigin ? -1 : "/");
    } catch {
      navigate("/");
    }
  };

  return (
    <html className="h-full">
      <head>
        <title>{title}</title>
        <Meta />
        <Links />
      </head>
      <body className="relative h-full">
        <div className="relative flex h-full flex-col items-center justify-center rounded-xl bg-white px-10 py-4 text-center dark:bg-gray-800">
          <div className="mb-4 flex h-full max-w-[350px] flex-1 flex-col items-center justify-center gap-3">
            <Alert className="mb-4 text-[57px] text-[#EF4444] md:mb-8" />
            <div className="text-lg font-medium uppercase text-black decoration-1 underline-offset-4 md:text-xl">
              {title}
            </div>
            <div className="text-lg font-light text-msgray-700">{message}</div>
            <div className="mt-6 flex w-full flex-col items-center gap-5 text-black">
              {!is404 &&
                (hasSent ? (
                  <div className="py-10 pb-14 text-xl">Thank you for submitting!</div>
                ) : (
                  <div className="flex w-full flex-col items-center gap-3">
                    <FormInput
                      placeholder="Describe what happened.."
                      value={userInput}
                      onChange={(e) => setUserInput(e.target.value)}
                      maxLength={1000}
                      rows={5}
                      onSubmit={sendErrorReport}
                      submitDisabled={isSending}
                      buttonText={isSending ? "Sending..." : "Send"}
                      buttonDesktopRounded={false}
                      inputWeight="font-light"
                    />
                    <p className="text-xl font-light text-msgray-950">or</p>
                  </div>
                ))}

              <BlackButton
                onClick={handleReturn}
                text="Return to previous page"
                mobileRounded={false}
                desktopRounded={false}
                className="w-full"
              />
            </div>
          </div>
        </div>
        <Scripts />
      </body>
    </html>
  );
}

function AppWithProviders() {
  const { userTheme } = useLoaderData();
  return (
    <ThemeProvider specifiedTheme={userTheme} themeAction="/action/set-theme">
      <AppProvider>
        <ReaderProvider>
          <App />
        </ReaderProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default withSentry(AppWithProviders);
